import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');
require('slick-carousel');
require('select2');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();



// SLICK CAROUSEL
$('.carousel').slick({
	dots: true,
	infinite: true,
	speed: 300,
	slidesToShow: 2,
	slidesToScroll: 1,
	swipe: true,
	variableWidth: false,
	responsive: [
		{
			breakpoint: 801,
			settings: {
			slidesToShow: 1,
			slidesToScroll: 1
			}
		}
	]
});


// SELECT2 ON MULTY-SELECT
$(document).ready(function() {
    $('.multy-select').select2();
});